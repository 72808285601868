import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {map} from 'lit/directives/map';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';

import {connect} from 'pwa-helpers';

import store from '../../../store';
import style from '../../../../../scss/checkbox.scss';
import {FormElement} from './formElement';

@customElement('arc-checkbox')
export class CheckBox extends FormElement {

	static properties = {
		...FormElement.properties,
		noFieldTitle: {type: Boolean}, // do not show field title or reserve space for it
		wrapperTitle: {type: String}, // the additional title displayed on top of the wrapper
	};

	static styles = [...FormElement.styles, style];

	initialValue = 0;
	constructor() {
		super();
		this.noFieldTitle = false;
	}

	handleValueChange(e) {
		const checked = e.target.checked? 1 : 0;
		this.inputValue = checked;
		this.dispatchValueChange(checked);
	}

	render() {
		const s = store.getState();

		return html`
			<div class="formField ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged(), disabled: this.disabled})}">
				${when(
					!this.noFieldTitle,
					() => html`<span class="wrapperTitle">${this.wrapperTitle ?? html`&nbsp;`}</span>`
				)}
				<div class="checkboxWrapper">
					<input type="checkbox" id="arcCheckbox" ?disabled=${this.disabled} .checked=${this.value === 1} @change=${this.handleValueChange}>
					<label for="arcCheckbox">
						<div class="title">${unsafeHTML(this.title)}</div>
						${when(this.tooltip, () => html`<arc-tooltip text=${this.tooltip}></arc-tooltip>`)}
					</label>
					${when(this.hasErrors(), () => html`<arc-errors .errors=${this.errors}></arc-errors>`)}
				</div>
			</div>
		`;
	}
}
