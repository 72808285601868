import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';

import style from '../../../../../scss/textInput.scss';
import {FormElement} from './formElement';

@customElement('arc-text-input')
export class TextInput extends FormElement {
	static properties = {
		...FormElement.properties,
		maxLength: {type: Number},
		placeholder: {type: String},
		multiline: {type: Boolean},

		currentLength: {type: Number}
	};

	static styles = [...FormElement.styles, style];

	initialValue = '';

	constructor() {
		super();
		this.maxLength = null;
		this.currentLength = 0;
	}

	handleValueChange(e) {
		let value = e.target.value;
		if (this.maxLength && this.maxLength > 0) value = value.substr(0, this.maxLength);
		this.currentLength = value.length;
		this.dispatchValueChange(value);
	}

	render() {
		return html`
			<div class="formField ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged(), disabled: this.disabled})}">
				<div class="textInputInfo">
					<div class="title">
						${this.title} ${when(this.required, () => html`<span class="requiredMark">*</span>`)}
						${when(this.maxLength, () => html`<span class="maxLength">(${this.currentLength} / max. ${this.maxLength} ${msg('Characters')})</span>`)}
					</div>
					${when(this.tooltip, () => html`
						<arc-tooltip text=${this.tooltip}></arc-tooltip>`)}
				</div>
				${this.multiline
					? html`
						<textarea
							placeholder="${this.placeholder}"
							required="${this.required}"
							maxlength=${this.maxLength ?? 99999}
							.value=${this.inputValue ?? ''}
							@change=${this.handleValueChange}
						></textarea>
					`
					: html`
						<input
							?required="${this.required}"
							?disabled=${this.disabled}
							maxlength=${this.maxLength ?? 99999}
							placeholder="${this.placeholder}"
							.value=${this.value ?? ''}
							@input=${this.handleValueChange}
						/>
					`}
				${when(this.hasErrors(), () => html`
					<arc-errors .errors=${this.errors}></arc-errors>`)}
			</div>
		`;
	}
}
