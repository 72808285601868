import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {msg} from '@lit/localize';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';
import {navigator, router} from 'lit-element-router';
import deepEqual from 'deep-equal';
import style from '../../../../scss/seriesDetail.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import store from '../../store';
import getRoutes from '../../routes';

import {
	fetchArtwork,
	fetchSeriesDetail,
	updateSeriesDetail,
	selectSeriesDetail,
	selectArtworkById, createSeries
} from '../../slices/artworkSlice';
import {deepClone} from '../../util/deepClone';
import {selectProfileDefaultLanguage} from '../../selectors';
import {routeUrl} from '../../util/routeUrl';
import {navigateTo, setFormChanged} from '../../slices/statusSlice';
import {isPermitted} from '../../util/isPermitted';

@customElement('arc-series-detail')
export class SeriesDetail extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, loadingSpinnerStyle, style];
	static properties = {
		seriesId: {type: String},
		seriesDetail: {type: Object},
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		currentLanguage: {type: String},
		disabled: {type: Boolean},
		isLoading: {type: Boolean},

		updateNeeded: {type: Boolean}
	};

	constructor() {
		super();
		this.seriesId = '';
		this.seriesDetail = null;
		this.localFormData = null;
		this.currentLanguage = 'de';
		this.disabled = false;
		this.isLoading = false;
		this.updateNeeded = false;

		this.create = true;
		this.initialValue = {
			profileId: null,
			title: null,
			subtitle: null,
			description: null,
			artworkIds: []
		};
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.formChanged = state.status.formChanged;
		this.seriesDetail = selectSeriesDetail(state);

		this.permittedToView = isPermitted(['artwork/view'], this.profileId, state);
		this.permittedToEdit = isPermitted(['artwork/edit'], this.profileId, state);
		this.currentLanguage = selectProfileDefaultLanguage(state);
		this.isLoading = state.artwork.loading === 'loading';
	}

	update(changedProperties) {
		super.update(changedProperties);

		// fetch new artwork data if needed, but only once
		if (this.updateNeeded) {
			this.fetchSeriesData();
		}
	}
	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {

		this.updateNeeded = (route === 'series-detail' || route === 'series-create') &&
			(this.seriesId !== params.seriesId);

		this.route = route;
		this.params = params;
		this.seriesId = params.seriesId;
		this.profileId = params.id; // profileId is needed for creating a new series

		this.requestUpdate();
	}

	async fetchSeriesData() {
		this.updateNeeded = false;

		if (this.route === 'series-detail') {
			// already existing series: fetch the data
			this.create = false;
			await store.dispatch(fetchSeriesDetail({seriesId: this.seriesId}));

			this.localFormData = deepClone(selectSeriesDetail(this.state));
			this.profileId = this.localFormData.profileId;
		}
		if (this.route === 'series-create') {
			// new series: create from initial values
			this.create = true;
			this.localFormData = deepClone(this.initialValue);
			this.localFormData.profileId = this.profileId;
		}
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;
		const newData = deepClone(this.localFormData);

		if (section) {
			if (newData[section]?.[field]) {
				newData[section] = newData[section] || {};
				newData[section][field] = value;
			} else {
				newData[section] = {...newData[section], [field]: value};
			}
		} else {
			newData[field] = value;
		}

		this.localFormData = newData;

		// when data is different, set form changed status to activate user confirmation on navigation
		store.dispatch(setFormChanged(this.formHasChanged()));

	}

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener('artwork-order-changed', this._onArtworkOrderChanged.bind(this));
		this.addEventListener('artwork-remove-from-series', this._onArtworkRemoveFromSeries.bind(this));
		store.dispatch(setFormChanged(false));
	}
	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEventListener('artwork-order-changed', this._onArtworkOrderChanged);
		this.removeEventListener('artwork-remove-from-series', this._onArtworkRemoveFromSeries);
		store.dispatch(setFormChanged(false));
	}

	_onArtworkOrderChanged(event) {
		const {artworkIds} = event.detail;
		this.localFormData.artworkIds = artworkIds;
		store.dispatch(setFormChanged(this.formHasChanged()));

		this.requestUpdate();
	}
	_onArtworkRemoveFromSeries(event) {
		const artworkId = event.detail.artworkId;
		const index = this.localFormData.artworkIds.indexOf(artworkId);
		if (index !== -1) {
			this.localFormData.artworkIds.splice(index, 1);
		}
		store.dispatch(setFormChanged(this.formHasChanged()));
		this.requestUpdate();
	}

	formHasChanged() {
		// check if local form data is different
		const newData = deepClone(this.localFormData ?? null);
		const oldData = deepClone(selectSeriesDetail(this.state) ?? null);
		if (newData && newData.hasOwnProperty('modified')) {
			delete newData.modified;
		}
		if (oldData && oldData.hasOwnProperty('modified')) {
			delete oldData.modified;
		}
		return !deepEqual(newData, oldData);
	}


	async saveSeries() {
		if (this.create) {
			await store.dispatch(createSeries({...this.localFormData, profileId: this.profileId}));
			this.navigate(routeUrl('series-list', {...this.params}));
			// store.dispatch(navigateTo({route: 'series-list', params: this.params}));
		} else {
			const updateData = {
				id: this.seriesId,
				...this.localFormData,
				artworkIds: this.localFormData.artworkIds
			};
			//  save the series including reordered artworkIds
			store.dispatch(updateSeriesDetail(updateData));
			store.dispatch(setFormChanged(false));
		}
	}

	render() {
		if (!this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}

		const title = this.localFormData.title?.[0]?.value || msg('Untitled');
		const artworkIds = this.localFormData.artworkIds || [];

		const basicInputFields = [
			{
				type: 'multilingualTextInput',
				title: msg('Title'),
				field: 'title',
				section: null,
				placeholder: '',
				data: this.localFormData,
				handler: this.handleValueChange.bind(this),
				required: true
			},
			{
				type: 'multilingualTextInput',
				title: msg('Subtitle'),
				field: 'subtitle',
				section: null,
				placeholder: '',
				data: this.localFormData,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Description'),
				field: 'description',
				section: null,
				placeholder: '',
				multiline: true,
				data: this.localFormData,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'date',
				title: msg('Start of Series'),
				field: 'startDate',
				section: '',
				granularity: 'year',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'date',
				title: msg('End of Series'),
				field: 'endDate',
				section: '',
				granularity: 'year',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
		];
		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
							<arc-routerlink route="series-list" }>${msg('Series')}</arc-routerlink>
							<span>${title}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-button
							title="${msg('Save Series')}"
							type="primary"
							?disabled="${!this.formHasChanged() || !this.permittedToEdit}"
							@click=${this.saveSeries}
						></arc-button>
					</div>
				</arc-toolbar>

				<header>
					<h2 class="pageTitle">${title}</h2>
					<arc-content-language-switch></arc-content-language-switch>
				</header>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Series Details')}</h2>
						<arc-form-grid
							class="formGrid basicAttributes"
							.fields=${basicInputFields}
							?disabled=${!this.permittedToEdit}
						></arc-form-grid>
					</div>
				</section>
				${when(!this.create,
					() => html`
						<section class="listOfArtworksSection">
							<h2 class="sectionTitle">${msg('Artworks in this Series')}</h2>
							<arc-artwork-list
								contextClass="seriesItem"
								.manualSortOrder=${this.localFormData?.artworkIds}
								noFilters="true"
							></arc-artwork-list>
						</section>`)}
			</div>
		`;
	}
}
